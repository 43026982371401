import axios from '@/common/js/request'
export function searchExtraFeeList(data) { // 工单管理安检任务名称下拉
    const searchExtraFeeList = axios({
        url: '/extraFee/searchExtraFeeList',
        method: 'post',
        data
    })
    return searchExtraFeeList
}
export function updateType(data){ // 工单管理安检任务名称下拉
    const updateType = axios({
        url: '/acceptanceInspection/updateType',
        method: 'post',
        data
    })
    return updateType
}
export function storeClerkList(params){ // 工单管理安检任务名称下拉
    const storeClerkList = axios({
        url: '/acceptanceInspection/storeClerkList',
        method: 'get',
        params
    })
    return storeClerkList
}
export function hiddenDetails(params){ // 工单管理安检任务名称下拉
    const hiddenDetails = axios({
        url: '/hidden/hiddenDetails',
        method: 'get',
        params
    })
    return hiddenDetails
}
export function allHiddenPass(params){ // 工单管理安检任务名称下拉
    const allHiddenPass = axios({
        url: '/hidden/allHiddenPass',
        method: 'get',
        params
    })
    return allHiddenPass
}
export function pcAllHiddenPass(params){ // 工单管理安检任务名称下拉
    const pcAllHiddenPass = axios({
        url: '/hidden/pcAllHiddenPass',
        method: 'get',
        params
    })
    return pcAllHiddenPass
}
export function delVillage(data){ // 工单管理安检任务名称下拉
    const delVillage = axios({
        url: '/indoor/delVillage',
        method: 'post',
        data
    })
    return delVillage
}
export function auditParticulars(params){ // 工单管理安检任务名称下拉
    const auditParticulars = axios({
        url: '/acceptanceInspection/auditParticulars',
        method: 'get',
        params
    })
    return auditParticulars
}
export function projectInfoListExportValidate(params){ // 工单管理安检任务名称下拉
    const projectInfoListExportValidate = axios({
        url: '/project/projectInfoListExportValidate',
        method: 'get',
        params
    })
    return projectInfoListExportValidate
}
export function countsSeriesLoggingExcel(params) {// daochu
    const archiveRecordsExcel = axios({
        url: '/acceptanceInspection/countsSeriesLoggingExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return archiveRecordsExcel
}
export function projectInfoListExport(data) {// daochu
    const projectInfoListExport = axios({
        url: '/project/projectInfoListExport',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return projectInfoListExport
}
export function materialProgressExport(params) {// daochu
    const materialProgressExport = axios({
        url: '/materialRecord/materialProgressExport',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return materialProgressExport
}
export function materialSummaryExport(params) {// daochu
    const materialSummaryExport = axios({
        url: '/materialRecord/materialSummaryExport',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return materialSummaryExport
}
export function countsSeriesLogging(params){ // 工单管理安检任务名称下拉
    const countsSeriesLogging = axios({
        url: '/acceptanceInspection/countsSeriesLogging',
        method: 'get',
        params
    })
    return countsSeriesLogging
}

export function auditLoggingList(params){ // 工单管理安检任务名称下拉
    const auditLoggingList = axios({
        url: '/acceptanceInspection/auditLoggingList',
        method: 'get',
        params
    })
    return auditLoggingList
}
export function countsSeriesList(params){ // 工单管理安检任务名称下拉
    const countsSeriesList = axios({
        url: '/acceptanceInspection/countsSeriesList',
        method: 'get',
        params
    })
    return countsSeriesList
}
export function paymentSave(data){ // 工单管理安检任务名称下拉
    const paymentSave = axios({
        url: '/payment/paymentSave',
        method: 'post',
        data
    })
    return paymentSave
}
export function paymentComplete(data) { // 工单管理安检任务名称下拉
    const paymentComplete = axios({
        url: '/payment/paymentComplete',
        method: 'post',
        data
    })
    return paymentComplete
}
export function searchExtraFeeDetail(params) { // 工单管理安检任务名称下拉
    const searchExtraFeeDetail = axios({
        url: '/extraFee/searchExtraFeeDetail',
        method: 'get',
        params
    })
    return searchExtraFeeDetail
}
export function doingProjectList(params){ // 工单管理安检任务名称下拉
    const doingProjectList = axios({
        url: '/materialRecord/doingProjectList',
        method: 'get',
        params
    })
    return doingProjectList
}
export function doneProjectList(params){ // 工单管理安检任务名称下拉
    const doneProjectList = axios({
        url: '/materialRecord/doneProjectList',
        method: 'get',
        params
    })
    return doneProjectList
}
export function materialSummary(params){ // 工单管理安检任务名称下拉
    const materialSummary = axios({
        url: '/materialRecord/materialSummary',
        method: 'get',
        params
    })
    return materialSummary
}
export function paymentingList(params){ // 工单管理安检任务名称下拉
    const paymentingList = axios({
        url: '/payment/paymentingList',
        method: 'get',
        params
    })
    return paymentingList
}
export function paymentCompleteList(params) { // 工单管理安检任务名称下拉
    const paymentCompleteList = axios({
        url: '/payment/paymentCompleteList',
        method: 'get',
        params
    })
    return paymentCompleteList
}
export function paymentRecord(params) { // 工单管理安检任务名称下拉
    const paymentRecord = axios({
        url: '/payment/paymentRecord',
        method: 'get',
        params
    })
    return paymentRecord
}
export function exportExtraFeeDetail(params) {
    const exportExtraFeeDetail = axios({
        url: '/extraFee/exportExtraFeeDetail',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return exportExtraFeeDetail
}
export function paymentRecordExport(params) {
    const paymentRecordExport = axios({
        url: '/payment/paymentRecordExport',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return paymentRecordExport
}
export function paymentDoneListExport(params) {
    const paymentDoneListExport = axios({
        url: '/payment/paymentDoneListExport',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return paymentDoneListExport
}
export function paymentingListExport(params) {
    const paymentingListExport = axios({
        url: '/payment/paymentingListExport',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return paymentingListExport
}
export function exportExtraFeeList(data) {
    const exportExtraFeeList = axios({
        url: '/extraFee/exportExtraFeeList',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return exportExtraFeeList
}

// 发料待审核列表
export function receiveWarehouseReviewList(params) { // 工单管理安检任务名称下拉
    const receiveWarehouseReviewList = axios({
        url: '/materialApply/receiveWarehouseReviewList',
        method: 'get',
        params
    })
    return receiveWarehouseReviewList
}
// 发料审核_查看更多
export function receiveWarehouseConfirmRecord(params) { // 工单管理安检任务名称下拉
    const receiveWarehouseConfirmRecord = axios({
        url: '/materialApply/receiveWarehouseConfirmRecord',
        method: 'get',
        params
    })
    return receiveWarehouseConfirmRecord
}

// 发料审核
export function receiveApplyWarehouseConfirm(data) {
    const receiveApplyWarehouseConfirm = axios({
        url: '/materialApply/receiveApplyWarehouseConfirm',
        method: 'post',
        data,
    })
    return receiveApplyWarehouseConfirm
}


// 待发料列表
export function provideWarehouseList(params) { // 工单管理安检任务名称下拉
    const provideWarehouseList = axios({
        url: '/materialApply/provideWarehouseList',
        method: 'get',
        params
    })
    return provideWarehouseList
}


// 待发料审核详情
export function receiveApplyInfo(params) { // 工单管理安检任务名称下拉
    const receiveApplyInfo = axios({
        url: '/materialApply/receiveApplyInfo',
        method: 'get',
        params
    })
    return receiveApplyInfo
}
// 物料导入
export function materialImport(data) {
    const materialImport = axios({
        url: '/materialRecord/materialImport',
        method: 'post',
        data,
    })
    return materialImport
}// 物料导入 非民导入 23-12-28 17:32
export function importUserExcel(data) {
    const importUserExcel = axios({
        url: '/AaumArchive/importUserExcel1',
        method: 'post',
        data,
    })
    return importUserExcel
}
// 物料导入保存
export function materialImportSave(data) {
    const materialImportSave = axios({
        url: '/materialRecord/materialImportSave',
        method: 'post',
        data,

    })
    return materialImportSave
}
// 发料记录
export function provideWarehouseRecord(params) {
    const provideWarehouseRecord = axios({
        url: '/materialApply/provideWarehouseRecord',
        method: 'get',
        params
    })
    return provideWarehouseRecord
}
// 发料详情
export function grantRecord(params) {
    const grantRecord = axios({
        url: '/materialRecord/grantRecord',
        method: 'get',
        params
    })
    return grantRecord
}

// 退料待审核列表
export function returnWarehouseReviewList(params) {
    const returnWarehouseReviewList = axios({
        url: '/materialApply/returnWarehouseReviewList',
        method: 'get',
        params
    })
    return returnWarehouseReviewList
}
// 退料申请详细信息
export function returnApplyInfo(params) {
    const returnApplyInfo = axios({
        url: '/materialApply/returnApplyInfo',
        method: 'get',
        params
    })
    return returnApplyInfo
}
// 退料审核
export function returnApplyWarehouseConfirm(data) {
    const returnApplyWarehouseConfirm = axios({
        url: '/materialApply/returnApplyWarehouseConfirm',
        method: 'post',
        data,

    })
    return returnApplyWarehouseConfirm
}
export function returnWarehouseConfirmRecord(params) {
    const returnWarehouseConfirmRecord = axios({
        url: '/materialApply/returnWarehouseConfirmRecordAPP',
        method: 'get',
        params
    })
    return returnWarehouseConfirmRecord
}


// 待退料
export function directWarehouseList(params) {
    const directWarehouseList = axios({
        url: '/materialApply/directWarehouseList',
        method: 'get',
        params
    })
    return directWarehouseList
}

// 直接退料列表
export function directWarehouseRecord(params) {
    const directWarehouseRecord = axios({
        url: '/materialApply/directWarehouseRecord',
        method: 'get',
        params
    })
    return directWarehouseRecord
}
// 直接退料明细
export function directReturnRecord(params) {
    const directReturnRecord = axios({
        url: '/materialRecord/directReturnRecord',
        method: 'get',
        params
    })
    return directReturnRecord
}
// 待调拨退料列表
export function allotWarehouseList(params) {
    const allotWarehouseList = axios({
        url: '/materialApply/allotWarehouseList',
        method: 'get',
        params
    })
    return allotWarehouseList
}

// 调拨退料记录

export function allotWarehouseRecord(params) {
    const allotWarehouseRecord = axios({
        url: '/materialApply/allotWarehouseRecord',
        method: 'get',
        params
    })
    return allotWarehouseRecord
}
// 调拨退料明细
export function returnToRecord(params) {
    const returnToRecord = axios({
        url: '/materialRecord/returnToRecord',
        method: 'get',
        params
    })
    return returnToRecord
}