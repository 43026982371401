<template>
  <div>
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          工程编号/名称:
          <el-input
              style="width: 200px"
              placeholder="请输入"
              size="small"
              v-model="searchBox.keyWord"
          ></el-input>
          <span style="margin-right: 20px"></span>
          安装施工队:
          <el-input
              style="width: 200px"
              placeholder="请输入"
              size="small"
              v-model="searchBox.constructionTeam"
          ></el-input>
          <span style="margin-right: 20px"></span>
          现场管理员:
          <el-input
              style="width: 200px"
              placeholder="请输入"
              size="small"
              v-model="searchBox.siteManager"
          ></el-input>
          <span style="margin-right: 20px"></span>
          审核结果:
          <el-select
              v-model="searchBox.reviewStatus"
              clearable
              placeholder="请选择"
              size="small"
          >
            <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
          <div style="margin-top: 10px">
            提交时间:
            <el-date-picker
                size="small"
                v-model="time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
                @change="timeChange"
            >
            </el-date-picker>
            <span style="margin-right: 20px"></span>
            <el-button
                type="primary"
                class="SearchBtn"
                size="small"
                @click="search"
            >查询</el-button
            >
            <el-button
                type="primary"
                class="SearchBtn"
                size="small"
                @click="resetData"
            >重置</el-button
            >
            <el-button
                type="primary"
                class="SearchBtn"
                size="small"
                @click="printQrcode"
            >批量下载二维码</el-button
            >
          </div>
        </div>
        <div class="table">
          <!--数据表格-->
          <el-table
              ref="tableData"
              :data="tableData"
              style="width: 100%; margin: 15px 0"
              border
              height="calc(100vh - 390px)"
              :stripe="true"
              :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
                type="index"
                label="序号"
                width="70"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="projectNumber"
                label="工程编号"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="projectName"
                label="工程名称"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="siteManager"
                label="现场管理员"
                width="100"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="constructionTeam"
                label="安装施工队"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="typeName"
                label="隐蔽工程类型"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="realname"
                label="提交人"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="createTime"
                label="提交时间"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="reviewerName"
                label="审核人"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="reviewTime"
                label="审核时间"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                prop="reviewStatusName"
                label="审核结果"
                align="center"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                label="操作"
                align="center"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                <el-button size="small" type="primary" @click="seeDetil(row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
                :current-page="searchBox.current"
                :background="true"
                :page-sizes="[30, 50, 100]"
                :page-size="searchBox.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="详情"
        :visible.sync="detildialogVisible"
        width="40%"
        @close="closeFrom('form')"
    >
      <div style="max-height: 560px; overflow: auto">
        <p class="detil-code">工程信息</p>
        <div style="padding: 10px;line-height: 40px">
          <div >工程编号：{{ project.projectNumber }}</div>
          <div >工程名称：{{ project.projectName }}</div>
          <div >工程类型：{{ project.projectType }}</div>
          <div >工程地点：{{ project.projectSite }}</div>
          <div >现场管理员：{{ project.siteManager }}</div>
          <div >安装施工队：{{ project.teamName }}</div>
        </div>
        <p class="detil-code">隐蔽工程信息</p>
        <div style="padding: 10px;line-height: 40px">
          <div >名称：{{ project.name }}</div>
          <div >隐蔽工程类型：{{ project.typeName }}</div>
          <div >埋深：{{ project.depth }}</div>
          <div >经纬度：{{ project.longitude }}-{{ project.latitude }}</div>
          <div style="display: flex">
            <div style="margin-right: 15px">工程照片：</div>
            <el-image
                style="width: 100px; height: 100px"
                :src="project.siteManagerUrl.split(',')[0]"
                :preview-src-list="project.siteManagerUrl.split(',')"
            >
            </el-image>
            <div style="margin-left: 10px">共{{ project.siteManagerUrl.split(",").length }}张</div>
          </div>
          <div >附加说明：{{ project.remarks }}</div>
          <div style="display: flex;line-height: 50px">
            <div style="flex: 1">提交人：{{ project.realname }}</div>
            <div style="flex: 1">提交时间：{{ project.createTime }}</div>
          </div>
        </div>
        <p class="detil-code">审核信息</p>
        <div style="display: flex; line-height: 50px">
          <div  style="flex: 1" v-if="project.reviewResult !='待审核'">
            <div style="flex: 1">
              审核人：{{ project.siteManager }}
            </div>
            <div style="flex: 1">
              审核时间：{{ project.reviewTime }}
            </div>
          </div>
          <div style="flex: 1">
            <div style="flex: 1">
              审核结果：{{ project.reviewResult }}
            </div>
            <div style="flex: 1" v-if="project.reviewResult=='已驳回'">
              驳回原因：{{ project.rejectReason }}
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="detildialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <AsetQrcodes ref="qrcode"/>
  </div>
</template>

<script>
import AsetQrcodes from "@/components/AsetQrcodes";
import {pcAllHiddenPass,hiddenDetails} from "@/RequestPort/cost/cost"
export default {
  name: "index",
  components:{
    AsetQrcodes
  },
  data(){
    return{
      project:{
        siteManagerUrl:''
      },
      time: [],
      typeList: [
        {label:'已通过',value:'1'},
        {label:'待审核',value:'0'},
        {label:'审核驳回',value:'2'},
      ],
      total:null,
      detildialogVisible:false,
      tableData: [],
      searchBox:{
        current:1,
        size: 100
      }
    }
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let t1 = year + "-" + month + "-" + day + " " + "00:00:00";
    let t2 = year + "-" + month + "-" + day + " " + "23:59:59";
    this.time.push(t1);
    this.time.push(t2);
    this.searchBox.startTime = t1;
    this.searchBox.endTime = t2;
    this.loadList(this.searchBox);
  },
  methods:{
    //批量打印二维码
    printQrcode(){
      //调用子组件打印
      let arr = [
        {
          id:328471,
          name:"33333",
          code:"AOFU-001"
        },
        {
          id:328231,
          name:"2222",
          code:"AOFU123-001"
        },
        {
          id:328123231,
          name:"1111",
          code:"AOFU121233-001"
        },{
          id:328471,
          name:"44444",
          code:"AOFU-001"
        },
        {
          id:328231,
          name:"55555",
          code:"AOFU123-001"
        },
        {
          id:328123231,
          name:"66666",
          code:"AOFU121233-001"
        },
      ]
      this.$refs.qrcode.printQrCode(arr)
    },
    loadList(obj){
      pcAllHiddenPass(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    resetData(){
      this.searchBox = {
        current:1,
        size: 100,
        searchText:'',
        install:'',

      }
      this.time =[]
      this.loadList(this.searchBox)
    },
    search(){
      this.loadList(this.searchBox)
    },
    timeChange(val) {
      if (val) {
        this.searchBox.startTime = val[0];
        this.searchBox.endTime = val[1];
      } else {
        this.searchBox.startTime = null;
        this.searchBox.endTime = null;
      }
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    seeDetil(row){
      hiddenDetails({id:row.id}).then(res=>{
        this.project = res.data
        if(res.data.photosUrl){
          this.project.siteManagerUrl = res.data.photosUrl
        }
        this.detildialogVisible = true
      })
    }
  }
}
</script>

<style scoped>
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
</style>